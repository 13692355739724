<template>
  <div>
    <v-row align="start">
      <v-col sm="12" md="12">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col cols="12" sm="12" md="3" >
                <h5>Usuarios</h5>
              </v-col>
              <v-col cols="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="userSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col cols="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.user)"
                >
                  Crear Usuario
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.user"
              :items="user_records"
              :items-per-page="-1"
              multi-sort
              item-key="id"
              class=" rounded-xl"
              :search="userSearch"
              :loading="loadingUserTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.user)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.user)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- User Form -->
    <v-row justify="center" >
      <v-form ref="createUserForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="userDialog" persistent max-width="600px">
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion usuario ${createUserForm.email}` : `Crear usuario` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      label="Usuario inicio de sesion"
                      v-model="createUserForm.username"
                      :rules="[createFormRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      label="E-mail"
                      v-model="createUserForm.email"
                      :rules="[createFormRules.email]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-autocomplete
                      class="text-caption"
                      v-model="createUserForm.user_role"
                      item-value="id"
                      dense
                      :items="role_records"
                      item-text="name"
                      label="Rol"
                      required
                      :rules="[createFormRules.required]"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row dense align="center">
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="password"
                      dense
                      label="Contraseña"
                      v-model="createUserForm.password"
                      :rules="[createFormRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="password"
                      dense
                      label="Confirmar contraseña"
                      v-model="confirm_password"
                      :rules="[createFormRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row dense align="center">
                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      label="Nombres"
                      v-model="createUserForm.first_name"
                      :rules="[createFormRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      label="Apellidos"
                      v-model="createUserForm.last_name"
                      :rules="[createFormRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.user)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.user)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.user)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

   

    <!-- DIALOG DELETE USER -->
    <v-dialog v-model="dialogDeleteUser" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar este usuario?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.user)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.user)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
  </div>
</template>

<script>
import {axiosN506} from '../api/axiosBd'
export default {
  props:{
    headers: {
      type: Object,
      required: true
    },
    boards: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      //loadings
      isLoading: false,
      loadingUserTable: false,
      //dialog delete
      dialogDeleteUser: false,
      //search
      userSearch: '',
      //records
      user_records: [],
      role_records: [],
      subfamily: [],
      //form
      btnEdithVisible: false,
      valid: false,
      userDialog: false,
      createUserForm: {},
      confirm_password: '',
      //form rules
      createFormRules: {
        required: value => !!value || 'El campo es requerido.',
        counter: value => value ? value.length <= 25 || 'Max 25 Caracteres': '',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      selectedRecord: null
    }
  },
  computed: {
  },
  methods: {
    async summitForm(method, board){
      this.isLoading = true
      let error = null
      let form = {}

      if (board == this.boards.user) {
        if (!this.$refs.createUserForm.validate()) {
          error = 'Favor llenar todos los campos requeridos'
        } else {
          if (this.createUserForm.password != this.confirm_password) {
            error = 'Las contraseñas no coinciden'
          } else {
            form = this.createUserForm
          }
        }
      }

      if (!error) {
        await this.$store.dispatch('verifyToken')

        if (method == 'POST') {
          axiosN506(`${board}/add`, {
            method: "POST",
            data: form,
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        } else if (method == 'PUT') {
          axiosN506(`${board}/update`, {
            method: "PUT",
            data: { id: this.selectedRecord, ...form },
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        }
      } else {
        //construir componente o hacer prototype para mensajes de error standar en la aplicacion
        this.$alert(error, 'Title', {
          confirmButtonText: 'OK',
          callback: action => {
            this.isLoading = false
          }
        })
      }
    },
    editItem(item, board){
      this.btnEdithVisible = true
      this.selectedRecord = item.id
      
      if (board == this.boards.user) {
        this.createUserForm.id = item.id
        this.createUserForm.username = item.username
        this.createUserForm.email = item.email
        this.createUserForm.user_role = item.user_role
        this.createUserForm.password = item.password
       
        this.userDialog = true
      }
    },
    deleteItemConfirm(board) {
      this.isLoading = true

      axiosN506(`${board}/delete`, {
        method: "DELETE",
        data: { id: this.selectedRecord },
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then(() => {
        this.isLoading = false
        this.getData(board)
        this.closeDelete(board)
      }).catch((err) => {
        console.error(err)
        alert(`error: ${err.response ? err.response : err}`)
        this.isLoading = false          
      })
    },
    openDelete(item, board) {
      if (board == this.boards.user) this.dialogDeleteUser = true

      this.selectedRecord = item.id
    },
    openCreateForm(board) {
      if (board == this.boards.user) this.userDialog = true
    },
    closeForm(board) {
      if (board == this.boards.user) {
        this.userDialog = false
        this.$refs.createUserForm.resetValidation()
        this.createUserForm = {}
        this.confirm_password = ''
      }
      this.btnEdithVisible = false
      this.isLoading = false
      this.selectedRecord = null
    },
    closeDelete(board) {
      this.selectedRecord = null

      if (board == this.boards.user) this.dialogDeleteUser = false
    },
    getData(board){
      this.loadingTable = true
      this.$store.dispatch('verifyToken')
      axiosN506({
        method: 'GET',
        url: `${board}/list`,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then((result) => {
        if (board == this.boards.user){
          this.loadingUserTable = false
          this.user_records = result.data.data
        } 
        
        if (board == 'role') this.role_records = result.data.data
      }).catch((err) => {
        console.error(err)
      });
    },
    convertToMoney(x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    itemTextSupplier_owner(item){
      return `${item.business_name}    ${item.first_name}`
    }
  },
  created() {
    this.getData(this.boards.user)
    this.getData('role')
  }
}
</script>