<template>
 <v-container fluid class="py-3 px-5">
   <v-row>
     <v-col cols="12" class="pb-1">
       <p class="ma-1">
        Usuarios
       </p>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="12" class="pt-1">
       <UsersTable :headers="headers" :boards="boards">

       </UsersTable>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import UsersTable from '@/components/UsersTable.vue' 
export default {
  name: 'Users',
  components: {
    UsersTable
  },
  data() {
    return {
      headers: {
        user: [
          {
            text: 'Nombre usuario',
            align: 'center',
            value: 'username',
          },
          {
            text: 'E-mail',
            align: 'center',
            value: 'email',
          },
          {
            text: 'Contraseña',
            align: 'center',
            value: 'password',
          },
          {
            text: 'Rol',
            align: 'center',
            value: 'user_role',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ],
      },
      boards: {
        user: 'user',
      }
    }
  },
}
</script>

<style>

</style>